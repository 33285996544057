import { j as u } from "./jsx-runtime-D0AQ1Mn7.js";
import * as i from "react";
import "react-dom";
import { c as v } from "./index-CYs0Zmg8.js";
import { c as C } from "./utils-CJ9afRe1.js";
function P(e, t, { checkForDefaultPrevented: n = !0 } = {}) {
  return function(r) {
    if (e == null || e(r), n === !1 || !r.defaultPrevented)
      return t == null ? void 0 : t(r);
  };
}
function g(e) {
  const t = i.useRef(e);
  return i.useEffect(() => {
    t.current = e;
  }), i.useMemo(() => (...n) => {
    var o;
    return (o = t.current) == null ? void 0 : o.call(t, ...n);
  }, []);
}
function R({
  prop: e,
  defaultProp: t,
  onChange: n = () => {
  }
}) {
  const [o, r] = x({ defaultProp: t, onChange: n }), s = e !== void 0, l = s ? e : o, a = g(n), c = i.useCallback(
    (f) => {
      if (s) {
        const p = typeof f == "function" ? f(e) : f;
        p !== e && a(p);
      } else
        r(f);
    },
    [s, e, r, a]
  );
  return [l, c];
}
function x({
  defaultProp: e,
  onChange: t
}) {
  const n = i.useState(e), [o] = n, r = i.useRef(o), s = g(t);
  return i.useEffect(() => {
    r.current !== o && (s(o), r.current = o);
  }, [o, r, s]), n;
}
function m(e, t) {
  if (typeof e == "function")
    return e(t);
  e != null && (e.current = t);
}
function j(...e) {
  return (t) => {
    let n = !1;
    const o = e.map((r) => {
      const s = m(r, t);
      return !n && typeof s == "function" && (n = !0), s;
    });
    if (n)
      return () => {
        for (let r = 0; r < o.length; r++) {
          const s = o[r];
          typeof s == "function" ? s() : m(e[r], null);
        }
      };
  };
}
var b = i.forwardRef((e, t) => {
  const { children: n, ...o } = e, r = i.Children.toArray(n), s = r.find(S);
  if (s) {
    const l = s.props.children, a = r.map((c) => c === s ? i.Children.count(l) > 1 ? i.Children.only(null) : i.isValidElement(l) ? l.props.children : null : c);
    return /* @__PURE__ */ u.jsx(d, { ...o, ref: t, children: i.isValidElement(l) ? i.cloneElement(l, void 0, a) : null });
  }
  return /* @__PURE__ */ u.jsx(d, { ...o, ref: t, children: n });
});
b.displayName = "Slot";
var d = i.forwardRef((e, t) => {
  const { children: n, ...o } = e;
  if (i.isValidElement(n)) {
    const r = E(n), s = w(o, n.props);
    return n.type !== i.Fragment && (s.ref = t ? j(t, r) : r), i.cloneElement(n, s);
  }
  return i.Children.count(n) > 1 ? i.Children.only(null) : null;
});
d.displayName = "SlotClone";
var N = ({ children: e }) => /* @__PURE__ */ u.jsx(u.Fragment, { children: e });
function S(e) {
  return i.isValidElement(e) && e.type === N;
}
function w(e, t) {
  const n = { ...t };
  for (const o in t) {
    const r = e[o], s = t[o];
    /^on[A-Z]/.test(o) ? r && s ? n[o] = (...a) => {
      s(...a), r(...a);
    } : r && (n[o] = r) : o === "style" ? n[o] = { ...r, ...s } : o === "className" && (n[o] = [r, s].filter(Boolean).join(" "));
  }
  return { ...e, ...n };
}
function E(e) {
  var o, r;
  let t = (o = Object.getOwnPropertyDescriptor(e.props, "ref")) == null ? void 0 : o.get, n = t && "isReactWarning" in t && t.isReactWarning;
  return n ? e.ref : (t = (r = Object.getOwnPropertyDescriptor(e, "ref")) == null ? void 0 : r.get, n = t && "isReactWarning" in t && t.isReactWarning, n ? e.props.ref : e.props.ref || e.ref);
}
var V = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
], O = V.reduce((e, t) => {
  const n = i.forwardRef((o, r) => {
    const { asChild: s, ...l } = o, a = s ? b : t;
    return typeof window < "u" && (window[Symbol.for("radix-ui")] = !0), /* @__PURE__ */ u.jsx(a, { ...l, ref: r });
  });
  return n.displayName = `Primitive.${t}`, { ...e, [t]: n };
}, {}), W = "Toggle", h = i.forwardRef((e, t) => {
  const { pressed: n, defaultPressed: o = !1, onPressedChange: r, ...s } = e, [l = !1, a] = R({
    prop: n,
    onChange: r,
    defaultProp: o
  });
  return /* @__PURE__ */ u.jsx(
    O.button,
    {
      type: "button",
      "aria-pressed": l,
      "data-state": l ? "on" : "off",
      "data-disabled": e.disabled ? "" : void 0,
      ...s,
      ref: t,
      onClick: P(e.onClick, () => {
        e.disabled || a(!l);
      })
    }
  );
});
h.displayName = W;
var y = h;
const A = v(
  "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors data-[state=on]:bg-accent data-[state=on]:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ring-offset-background hover:bg-muted hover:text-muted-foreground",
  {
    variants: {
      variant: {
        default: "bg-transparent",
        outline: "bg-transparent border border-input hover:bg-accent hover:text-accent-foreground"
      },
      size: {
        md: "h-10 py-2 px-4",
        sm: "h-8 px-2 rounded-md",
        lg: "h-11 px-8 rounded-md"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "md"
    }
  }
), T = i.forwardRef(({ className: e, variant: t, size: n, ...o }, r) => /* @__PURE__ */ u.jsx(
  y,
  {
    ref: r,
    className: C(A({ variant: t, size: n, className: e })),
    ...o
  }
));
T.displayName = y.displayName;
export {
  T
};
