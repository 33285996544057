import { j as i } from "./jsx-runtime-D0AQ1Mn7.js";
import { c as l } from "./index-CYs0Zmg8.js";
import * as b from "react";
import { c as d } from "./utils-CJ9afRe1.js";
const c = l(
  "inline-flex items-center border shrink-0 rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      circle: {
        true: "flex items-center justify-center"
      },
      size: {
        xs: "w-2 h-2 text-xxs",
        md: "w-5 h-5 text-xs",
        auto: "px-2.5 py-0.5",
        default: " text-xs px-2.5 py-0.5"
      },
      variant: {
        light: "bg-blue-10 font-medium text-black-primary border-blue-10",
        label: "bg-blue-50 border-blue-50 border-transparent text-black-primary",
        default: "bg-primary  border-transparent text-primary-foreground",
        secondary: "bg-secondary  border-transparent text-secondary-foreground",
        destructive: "bg-badge-destructive border-badge-destructive text-badge-destructive-foreground",
        outline: "text-foreground",
        success: "bg-green-20 border-transparent text-green-200",
        info: "bg-blue-30 border-transparent text-blue-100",
        warning: "bg-yellow-60 border-transparent text-black-primary",
        lightYellow: "bg-yellow-50 border-transparent text-black-primary",
        lightSecondary: "bg-gray-neutral-30 border-transparent text-black-primary",
        custom: "",
        purple: "bg-purple-30 border-transparent text-black-primary",
        white: "bg-white border-transparent text-black-primary"
      }
    },
    defaultVariants: {
      variant: "default",
      circle: !1,
      size: "default"
    }
  }
), u = b.forwardRef(
  ({ className: r, size: e, variant: t, circle: a, children: n, ...o }, s) => /* @__PURE__ */ i.jsx(
    "div",
    {
      ref: s,
      className: d(c({ variant: t, size: e, circle: a }), r),
      ...o,
      children: n
    }
  )
);
u.displayName = "Badge";
export {
  u as B
};
