import Icon from 'components/dist/atoms/Icon';
import Head from 'next/head';
import Image from 'next/image';
import type { FC } from 'react';

export const SplashScreen: FC = () => (
  <>
    <Head>
      <title>
        Loading...
      </title>
    </Head>
    <div
      className='p-6  inset-0 fixed w-screen bg-black-primary flex h-dvh items-center flex-col justify-center z-splash'

    >
      <div
        className='h9'>
        <Image
          layout='fixed'
          src='/static/232px_DarkMode.png?v=26'
          alt="mysherpas-logo"
          width='172'
          height='44'
        />
      </div>
      <div className='mt-4'>
        <Icon name="Refresh" width={42} height={42} className="animate-spin text-white [&_path:last-child]:hidden" />
      </div>
    </div>
  </>
);
