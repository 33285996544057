import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo } from 'react';
import type { AppUserForgotPasswordRequestDto, SecurityResponseDto, VerifyConfirmationCodeDto } from 'src/backend';
import { Route } from 'src/constants/routing';
import { QUERY_PARAM_RETURN_URL } from 'src/constants/url';
import { useUpdateCookieTimeoutMutation } from 'src/services/authApi';
import { useGetLoggedInUserQuery } from 'src/services/userApi';
import { ApiResponse } from 'src/types/api';
import { AppUserDTO2Extended } from 'src/types/user';
import { isRtkErrorWithData } from 'src/utils/is-rtk-error-with-message';

import { api } from "../api";
import {
    ChangePasswordFormValues,
    ConfirmForgotPasswordRequest,
    ConfirmSignupRequest,
    ForgotPasswordRequest,
    LoginRequest,
    NewPasswordRequest,
    SignUpRequest
} from "../types/auth";

interface AuthProviderProps {
    children: React.ReactNode;
}

interface State {
    isInitialized: boolean;
    isAuthenticated: boolean;
    isSessionTimedOut: boolean;
    user: AppUserDTO2Extended;
}

interface AuthContextValue extends State {
    login: (info: LoginRequest) => Promise<SecurityResponseDto>;
    logout: (returnUrl?: string) => Promise<any>;
    authenticate: () => Promise<any>;
    verifyConfirmationCode: (info: AppUserForgotPasswordRequestDto) => Promise<VerifyConfirmationCodeDto>;
    changePassword: (values: ChangePasswordFormValues) => Promise<ApiResponse>;
    newPassword: (info: NewPasswordRequest) => Promise<any>;
    signUp: (info: SignUpRequest) => Promise<any>;
    refetchCurrentUser: () => void;
    confirmSignUp: (info: ConfirmSignupRequest) => Promise<any>;
    forgotPassword: (info: ForgotPasswordRequest) => Promise<any>;
    confirmForgotPassword: (info: ConfirmForgotPasswordRequest) => Promise<any>;
}

const initialState: State = {
    isInitialized: false,
    isAuthenticated: false,
    isSessionTimedOut: false,
    user: null,
}

export const AuthContext = React.createContext<AuthContextValue>({
    ...initialState,
    login: () => Promise.resolve(null),
    logout: () => Promise.resolve(),
    authenticate: () => Promise.resolve(),
    newPassword: () => Promise.resolve(),
    verifyConfirmationCode: () => Promise.resolve({ confirmed: false, confirmationCode2: '' }),
    signUp: () => Promise.resolve(),
    refetchCurrentUser: () => Promise.resolve(),
    confirmSignUp: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    confirmForgotPassword: () => Promise.resolve(),
    changePassword: () => Promise.resolve({} as ApiResponse),
});

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
    const { currentData, refetch, isSuccess, isError, error } = useGetLoggedInUserQuery();
    const [updateCookieTimeout] = useUpdateCookieTimeoutMutation();
    const [isSessionTimedOut, setIsSessionTimedOut] = React.useState(false);
    const [failedAttempts, setFailedAttempts] = React.useState(0);
    const isAuthenticated = typeof currentData?.user !== 'undefined'
    // check if the app is running on a valid subdomain
    // if not, redirect to the landing page
    const router = useRouter();

    const login = useCallback(async (info: LoginRequest): Promise<SecurityResponseDto> => {
        let lResult = await api.login(info);
        if (lResult?.success === true) {
            // successful login
            refetch()
            setIsSessionTimedOut(false);
        }

        return lResult;
    }, [refetch]);

    const logout = useCallback(async (returnUrl?: string): Promise<any> => {
        router.push({
            pathname: Route.LOGOUT, query: {
                [QUERY_PARAM_RETURN_URL]: returnUrl
            }
        });
    }, [router]);

    const authenticate = useCallback(async (): Promise<void> => {
        const result = await api.authenticate();
        if (result.success !== true) {
            setFailedAttempts(prev => {
                const newAttempts = prev + 1;
                if (newAttempts > 3) {
                    setIsSessionTimedOut(true);
                }
                return newAttempts;
            });
        } else {
            setFailedAttempts(0);
        }
    }, []);

    const newPassword = useCallback(async (info: NewPasswordRequest): Promise<ApiResponse> => {
        return api.newPassword(info);
    }, []);

    const signUp = useCallback(async (info: SignUpRequest): Promise<ApiResponse> => {
        return api.signup(info);
    }, []);

    const changePassword = useCallback((values: ChangePasswordFormValues): Promise<ApiResponse> => {
        return api.changePassword(values);
    }, []);

    const confirmSignUp = useCallback(async (info: ConfirmSignupRequest): Promise<ApiResponse> => {
        return api.confirmSignup(info);
    }, []);

    const forgotPassword = useCallback(async (info: ForgotPasswordRequest): Promise<ApiResponse> => {
        return api.forgotPassword(info);
    }, []);

    const confirmForgotPassword = useCallback(async (info: ConfirmForgotPasswordRequest): Promise<ApiResponse> => {
        const result = await api.confirmForgotPassword(info);
        if (result.success) {
            // refresh the user data
            refetch();
        }
        return result;
    }, [refetch]);

    const verifyConfirmationCode = useCallback(async (info: AppUserForgotPasswordRequestDto): Promise<VerifyConfirmationCodeDto> => {
        return api.verifyConfirmationCode(info);
    }, []);

    const refetchCurrentUser = useCallback(() => {
        setIsSessionTimedOut(false);
        refetch();
    }, [refetch]);

    // useEffect(function checkIfSubdomainIsValid() {
    //     checkSubdomainValidity();

    // }, [checkSubdomainValidity]);

    const isSSOLogin = isAuthenticated && router.isReady && router.query?.sso === "1";

    useEffect(function handleRefetchError() {
        // if error is Network Error
        // then refresh the page
        if (isRtkErrorWithData(error) && error.data === "Network Error") {
            window.location.reload();
        }
    }, [error])

    useEffect(function updateCookieTimeoutIfSignedInWithSSO() {
        if (isSSOLogin) {
            (async () => {
                const format = await import('date-fns').then((m) => m.format);
                updateCookieTimeout({
                    timestamp: format(new Date(), 'HH:mm:ss'),
                    remember: true,
                });
            })()
        }
    }, [isSSOLogin, updateCookieTimeout]);

    const value = useMemo(() => {
        return {
            isAuthenticated,
            user: currentData?.user,
            isInitialized: isSuccess || isError,
            isSessionTimedOut,
            login,
            logout,
            authenticate,
            newPassword,
            signUp,
            verifyConfirmationCode,
            changePassword,
            confirmSignUp,
            forgotPassword,
            refetchCurrentUser,
            confirmForgotPassword,
        }
    }, [isAuthenticated, currentData?.user, isSuccess, isError, isSessionTimedOut, login, logout, authenticate, newPassword, signUp, verifyConfirmationCode, changePassword, confirmSignUp, forgotPassword, refetchCurrentUser, confirmForgotPassword]);

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    );
};

export const AuthConsumer = AuthContext.Consumer;
