var W = function(r) {
  if (typeof document > "u")
    return null;
  var u = Array.isArray(r) ? r[0] : r;
  return u.ownerDocument.body;
}, f = /* @__PURE__ */ new WeakMap(), v = /* @__PURE__ */ new WeakMap(), p = {}, h = 0, M = function(r) {
  return r && (r.host || M(r.parentNode));
}, S = function(r, u) {
  return u.map(function(t) {
    if (r.contains(t))
      return t;
    var a = M(t);
    return a && r.contains(a) ? a : (console.error("aria-hidden", t, "in not contained inside", r, ". Doing nothing"), null);
  }).filter(function(t) {
    return !!t;
  });
}, D = function(r, u, t, a) {
  var i = S(u, Array.isArray(r) ? r : [r]);
  p[t] || (p[t] = /* @__PURE__ */ new WeakMap());
  var o = p[t], l = [], s = /* @__PURE__ */ new Set(), b = new Set(i), y = function(e) {
    !e || s.has(e) || (s.add(e), y(e.parentNode));
  };
  i.forEach(y);
  var A = function(e) {
    !e || b.has(e) || Array.prototype.forEach.call(e.children, function(n) {
      if (s.has(n))
        A(n);
      else
        try {
          var c = n.getAttribute(a), d = c !== null && c !== "false", w = (f.get(n) || 0) + 1, _ = (o.get(n) || 0) + 1;
          f.set(n, w), o.set(n, _), l.push(n), w === 1 && d && v.set(n, !0), _ === 1 && n.setAttribute(t, "true"), d || n.setAttribute(a, "true");
        } catch (k) {
          console.error("aria-hidden: cannot operate on ", n, k);
        }
    });
  };
  return A(u), s.clear(), h++, function() {
    l.forEach(function(e) {
      var n = f.get(e) - 1, c = o.get(e) - 1;
      f.set(e, n), o.set(e, c), n || (v.has(e) || e.removeAttribute(a), v.delete(e)), c || e.removeAttribute(t);
    }), h--, h || (f = /* @__PURE__ */ new WeakMap(), f = /* @__PURE__ */ new WeakMap(), v = /* @__PURE__ */ new WeakMap(), p = {});
  };
}, E = function(r, u, t) {
  t === void 0 && (t = "data-aria-hidden");
  var a = Array.from(Array.isArray(r) ? r : [r]), i = W(r);
  return i ? (a.push.apply(a, Array.from(i.querySelectorAll("[aria-live]"))), D(a, i, t, "aria-hidden")) : function() {
    return null;
  };
}, C = function() {
  if (typeof __webpack_nonce__ < "u")
    return __webpack_nonce__;
};
export {
  C as g,
  E as h
};
