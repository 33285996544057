import * as o from "react";
import X from "react";
import { j as g } from "./jsx-runtime-D0AQ1Mn7.js";
import * as vn from "react-dom";
import hn from "react-dom";
import { u as gn, o as Cn, s as En, f as xn, a as Sn, b as yn, h as wn, l as bn, c as Pn } from "./floating-ui.react-dom-88_vs_AG.js";
import { h as Rn } from "./index-pK5bN1zh.js";
import { _ as Mn, a as ne, b as _n } from "./tslib.es6-CR4a2p76.js";
import { c as In, u as An, z as Nn, f as Tn, s as On, R as Fn, e as Dn } from "./component-3UG5q_P8.js";
function R(e, t, { checkForDefaultPrevented: n = !0 } = {}) {
  return function(s) {
    if (e == null || e(s), n === !1 || !s.defaultPrevented)
      return t == null ? void 0 : t(s);
  };
}
function Ln(e, t = []) {
  let n = [];
  function r(c, i) {
    const a = o.createContext(i), d = n.length;
    n = [...n, i];
    function u(l) {
      const { scope: m, children: h, ...p } = l, f = (m == null ? void 0 : m[e][d]) || a, C = o.useMemo(() => p, Object.values(p));
      return /* @__PURE__ */ g.jsx(f.Provider, { value: C, children: h });
    }
    function v(l, m) {
      const h = (m == null ? void 0 : m[e][d]) || a, p = o.useContext(h);
      if (p) return p;
      if (i !== void 0) return i;
      throw new Error(`\`${l}\` must be used within \`${c}\``);
    }
    return u.displayName = c + "Provider", [u, v];
  }
  const s = () => {
    const c = n.map((i) => o.createContext(i));
    return function(a) {
      const d = (a == null ? void 0 : a[e]) || c;
      return o.useMemo(
        () => ({ [`__scope${e}`]: { ...a, [e]: d } }),
        [a, d]
      );
    };
  };
  return s.scopeName = e, [r, kn(s, ...t)];
}
function kn(...e) {
  const t = e[0];
  if (e.length === 1) return t;
  const n = () => {
    const r = e.map((s) => ({
      useScope: s(),
      scopeName: s.scopeName
    }));
    return function(c) {
      const i = r.reduce((a, { useScope: d, scopeName: u }) => {
        const l = d(c)[`__scope${u}`];
        return { ...a, ...l };
      }, {});
      return o.useMemo(() => ({ [`__scope${t.scopeName}`]: i }), [i]);
    };
  };
  return n.scopeName = t.scopeName, n;
}
function jn(e, t) {
  typeof e == "function" ? e(t) : e != null && (e.current = t);
}
function ke(...e) {
  return (t) => e.forEach((n) => jn(n, t));
}
function T(...e) {
  return o.useCallback(ke(...e), e);
}
var ue = o.forwardRef((e, t) => {
  const { children: n, ...r } = e, s = o.Children.toArray(n), c = s.find(Un);
  if (c) {
    const i = c.props.children, a = s.map((d) => d === c ? o.Children.count(i) > 1 ? o.Children.only(null) : o.isValidElement(i) ? i.props.children : null : d);
    return /* @__PURE__ */ g.jsx(Ne, { ...r, ref: t, children: o.isValidElement(i) ? o.cloneElement(i, void 0, a) : null });
  }
  return /* @__PURE__ */ g.jsx(Ne, { ...r, ref: t, children: n });
});
ue.displayName = "Slot";
var Ne = o.forwardRef((e, t) => {
  const { children: n, ...r } = e;
  if (o.isValidElement(n)) {
    const s = Kn(n);
    return o.cloneElement(n, {
      ...Bn(r, n.props),
      // @ts-ignore
      ref: t ? ke(t, s) : s
    });
  }
  return o.Children.count(n) > 1 ? o.Children.only(null) : null;
});
Ne.displayName = "SlotClone";
var $n = ({ children: e }) => /* @__PURE__ */ g.jsx(g.Fragment, { children: e });
function Un(e) {
  return o.isValidElement(e) && e.type === $n;
}
function Bn(e, t) {
  const n = { ...t };
  for (const r in t) {
    const s = e[r], c = t[r];
    /^on[A-Z]/.test(r) ? s && c ? n[r] = (...a) => {
      c(...a), s(...a);
    } : s && (n[r] = s) : r === "style" ? n[r] = { ...s, ...c } : r === "className" && (n[r] = [s, c].filter(Boolean).join(" "));
  }
  return { ...e, ...n };
}
function Kn(e) {
  var r, s;
  let t = (r = Object.getOwnPropertyDescriptor(e.props, "ref")) == null ? void 0 : r.get, n = t && "isReactWarning" in t && t.isReactWarning;
  return n ? e.ref : (t = (s = Object.getOwnPropertyDescriptor(e, "ref")) == null ? void 0 : s.get, n = t && "isReactWarning" in t && t.isReactWarning, n ? e.props.ref : e.props.ref || e.ref);
}
function it(e) {
  const t = e + "CollectionProvider", [n, r] = Ln(t), [s, c] = n(
    t,
    { collectionRef: { current: null }, itemMap: /* @__PURE__ */ new Map() }
  ), i = (h) => {
    const { scope: p, children: f } = h, C = X.useRef(null), E = X.useRef(/* @__PURE__ */ new Map()).current;
    return /* @__PURE__ */ g.jsx(s, { scope: p, itemMap: E, collectionRef: C, children: f });
  };
  i.displayName = t;
  const a = e + "CollectionSlot", d = X.forwardRef(
    (h, p) => {
      const { scope: f, children: C } = h, E = c(a, f), x = T(p, E.collectionRef);
      return /* @__PURE__ */ g.jsx(ue, { ref: x, children: C });
    }
  );
  d.displayName = a;
  const u = e + "CollectionItemSlot", v = "data-radix-collection-item", l = X.forwardRef(
    (h, p) => {
      const { scope: f, children: C, ...E } = h, x = X.useRef(null), y = T(p, x), w = c(u, f);
      return X.useEffect(() => (w.itemMap.set(x, { ref: x, ...E }), () => void w.itemMap.delete(x))), /* @__PURE__ */ g.jsx(ue, { [v]: "", ref: y, children: C });
    }
  );
  l.displayName = u;
  function m(h) {
    const p = c(e + "CollectionConsumer", h);
    return X.useCallback(() => {
      const C = p.collectionRef.current;
      if (!C) return [];
      const E = Array.from(C.querySelectorAll(`[${v}]`));
      return Array.from(p.itemMap.values()).sort(
        (w, P) => E.indexOf(w.ref.current) - E.indexOf(P.ref.current)
      );
    }, [p.collectionRef, p.itemMap]);
  }
  return [
    { Provider: i, Slot: d, ItemSlot: l },
    m,
    r
  ];
}
function Wn(e, t = []) {
  let n = [];
  function r(c, i) {
    const a = o.createContext(i), d = n.length;
    n = [...n, i];
    const u = (l) => {
      var E;
      const { scope: m, children: h, ...p } = l, f = ((E = m == null ? void 0 : m[e]) == null ? void 0 : E[d]) || a, C = o.useMemo(() => p, Object.values(p));
      return /* @__PURE__ */ g.jsx(f.Provider, { value: C, children: h });
    };
    u.displayName = c + "Provider";
    function v(l, m) {
      var f;
      const h = ((f = m == null ? void 0 : m[e]) == null ? void 0 : f[d]) || a, p = o.useContext(h);
      if (p) return p;
      if (i !== void 0) return i;
      throw new Error(`\`${l}\` must be used within \`${c}\``);
    }
    return [u, v];
  }
  const s = () => {
    const c = n.map((i) => o.createContext(i));
    return function(a) {
      const d = (a == null ? void 0 : a[e]) || c;
      return o.useMemo(
        () => ({ [`__scope${e}`]: { ...a, [e]: d } }),
        [a, d]
      );
    };
  };
  return s.scopeName = e, [r, Gn(s, ...t)];
}
function Gn(...e) {
  const t = e[0];
  if (e.length === 1) return t;
  const n = () => {
    const r = e.map((s) => ({
      useScope: s(),
      scopeName: s.scopeName
    }));
    return function(c) {
      const i = r.reduce((a, { useScope: d, scopeName: u }) => {
        const l = d(c)[`__scope${u}`];
        return { ...a, ...l };
      }, {});
      return o.useMemo(() => ({ [`__scope${t.scopeName}`]: i }), [i]);
    };
  };
  return n.scopeName = t.scopeName, n;
}
var Hn = o.createContext(void 0);
function ut(e) {
  const t = o.useContext(Hn);
  return e || t || "ltr";
}
var Yn = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
], O = Yn.reduce((e, t) => {
  const n = o.forwardRef((r, s) => {
    const { asChild: c, ...i } = r, a = c ? ue : t;
    return typeof window < "u" && (window[Symbol.for("radix-ui")] = !0), /* @__PURE__ */ g.jsx(a, { ...i, ref: s });
  });
  return n.displayName = `Primitive.${t}`, { ...e, [t]: n };
}, {});
function lt(e, t) {
  e && vn.flushSync(() => e.dispatchEvent(t));
}
function j(e) {
  const t = o.useRef(e);
  return o.useEffect(() => {
    t.current = e;
  }), o.useMemo(() => (...n) => {
    var r;
    return (r = t.current) == null ? void 0 : r.call(t, ...n);
  }, []);
}
function Vn(e, t = globalThis == null ? void 0 : globalThis.document) {
  const n = j(e);
  o.useEffect(() => {
    const r = (s) => {
      s.key === "Escape" && n(s);
    };
    return t.addEventListener("keydown", r, { capture: !0 }), () => t.removeEventListener("keydown", r, { capture: !0 });
  }, [n, t]);
}
var Xn = "DismissableLayer", Te = "dismissableLayer.update", zn = "dismissableLayer.pointerDownOutside", Zn = "dismissableLayer.focusOutside", qe, dt = o.createContext({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), ft = o.forwardRef(
  (e, t) => {
    const {
      disableOutsidePointerEvents: n = !1,
      onEscapeKeyDown: r,
      onPointerDownOutside: s,
      onFocusOutside: c,
      onInteractOutside: i,
      onDismiss: a,
      ...d
    } = e, u = o.useContext(dt), [v, l] = o.useState(null), m = (v == null ? void 0 : v.ownerDocument) ?? (globalThis == null ? void 0 : globalThis.document), [, h] = o.useState({}), p = T(t, (b) => l(b)), f = Array.from(u.layers), [C] = [...u.layersWithOutsidePointerEventsDisabled].slice(-1), E = f.indexOf(C), x = v ? f.indexOf(v) : -1, y = u.layersWithOutsidePointerEventsDisabled.size > 0, w = x >= E, P = Jn((b) => {
      const M = b.target, A = [...u.branches].some((F) => F.contains(M));
      !w || A || (s == null || s(b), i == null || i(b), b.defaultPrevented || a == null || a());
    }, m), _ = er((b) => {
      const M = b.target;
      [...u.branches].some((F) => F.contains(M)) || (c == null || c(b), i == null || i(b), b.defaultPrevented || a == null || a());
    }, m);
    return Vn((b) => {
      x === u.layers.size - 1 && (r == null || r(b), !b.defaultPrevented && a && (b.preventDefault(), a()));
    }, m), o.useEffect(() => {
      if (v)
        return n && (u.layersWithOutsidePointerEventsDisabled.size === 0 && (qe = m.body.style.pointerEvents, m.body.style.pointerEvents = "none"), u.layersWithOutsidePointerEventsDisabled.add(v)), u.layers.add(v), Qe(), () => {
          n && u.layersWithOutsidePointerEventsDisabled.size === 1 && (m.body.style.pointerEvents = qe);
        };
    }, [v, m, n, u]), o.useEffect(() => () => {
      v && (u.layers.delete(v), u.layersWithOutsidePointerEventsDisabled.delete(v), Qe());
    }, [v, u]), o.useEffect(() => {
      const b = () => h({});
      return document.addEventListener(Te, b), () => document.removeEventListener(Te, b);
    }, []), /* @__PURE__ */ g.jsx(
      O.div,
      {
        ...d,
        ref: p,
        style: {
          pointerEvents: y ? w ? "auto" : "none" : void 0,
          ...e.style
        },
        onFocusCapture: R(e.onFocusCapture, _.onFocusCapture),
        onBlurCapture: R(e.onBlurCapture, _.onBlurCapture),
        onPointerDownCapture: R(
          e.onPointerDownCapture,
          P.onPointerDownCapture
        )
      }
    );
  }
);
ft.displayName = Xn;
var qn = "DismissableLayerBranch", Qn = o.forwardRef((e, t) => {
  const n = o.useContext(dt), r = o.useRef(null), s = T(t, r);
  return o.useEffect(() => {
    const c = r.current;
    if (c)
      return n.branches.add(c), () => {
        n.branches.delete(c);
      };
  }, [n.branches]), /* @__PURE__ */ g.jsx(O.div, { ...e, ref: s });
});
Qn.displayName = qn;
function Jn(e, t = globalThis == null ? void 0 : globalThis.document) {
  const n = j(e), r = o.useRef(!1), s = o.useRef(() => {
  });
  return o.useEffect(() => {
    const c = (a) => {
      if (a.target && !r.current) {
        let d = function() {
          mt(
            zn,
            n,
            u,
            { discrete: !0 }
          );
        };
        const u = { originalEvent: a };
        a.pointerType === "touch" ? (t.removeEventListener("click", s.current), s.current = d, t.addEventListener("click", s.current, { once: !0 })) : d();
      } else
        t.removeEventListener("click", s.current);
      r.current = !1;
    }, i = window.setTimeout(() => {
      t.addEventListener("pointerdown", c);
    }, 0);
    return () => {
      window.clearTimeout(i), t.removeEventListener("pointerdown", c), t.removeEventListener("click", s.current);
    };
  }, [t, n]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => r.current = !0
  };
}
function er(e, t = globalThis == null ? void 0 : globalThis.document) {
  const n = j(e), r = o.useRef(!1);
  return o.useEffect(() => {
    const s = (c) => {
      c.target && !r.current && mt(Zn, n, { originalEvent: c }, {
        discrete: !1
      });
    };
    return t.addEventListener("focusin", s), () => t.removeEventListener("focusin", s);
  }, [t, n]), {
    onFocusCapture: () => r.current = !0,
    onBlurCapture: () => r.current = !1
  };
}
function Qe() {
  const e = new CustomEvent(Te);
  document.dispatchEvent(e);
}
function mt(e, t, n, { discrete: r }) {
  const s = n.originalEvent.target, c = new CustomEvent(e, { bubbles: !1, cancelable: !0, detail: n });
  t && s.addEventListener(e, t, { once: !0 }), r ? lt(s, c) : s.dispatchEvent(c);
}
var Re = 0;
function tr() {
  o.useEffect(() => {
    const e = document.querySelectorAll("[data-radix-focus-guard]");
    return document.body.insertAdjacentElement("afterbegin", e[0] ?? Je()), document.body.insertAdjacentElement("beforeend", e[1] ?? Je()), Re++, () => {
      Re === 1 && document.querySelectorAll("[data-radix-focus-guard]").forEach((t) => t.remove()), Re--;
    };
  }, []);
}
function Je() {
  const e = document.createElement("span");
  return e.setAttribute("data-radix-focus-guard", ""), e.tabIndex = 0, e.style.outline = "none", e.style.opacity = "0", e.style.position = "fixed", e.style.pointerEvents = "none", e;
}
var Me = "focusScope.autoFocusOnMount", _e = "focusScope.autoFocusOnUnmount", et = { bubbles: !1, cancelable: !0 }, nr = "FocusScope", pt = o.forwardRef((e, t) => {
  const {
    loop: n = !1,
    trapped: r = !1,
    onMountAutoFocus: s,
    onUnmountAutoFocus: c,
    ...i
  } = e, [a, d] = o.useState(null), u = j(s), v = j(c), l = o.useRef(null), m = T(t, (f) => d(f)), h = o.useRef({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  o.useEffect(() => {
    if (r) {
      let f = function(y) {
        if (h.paused || !a) return;
        const w = y.target;
        a.contains(w) ? l.current = w : G(l.current, { select: !0 });
      }, C = function(y) {
        if (h.paused || !a) return;
        const w = y.relatedTarget;
        w !== null && (a.contains(w) || G(l.current, { select: !0 }));
      }, E = function(y) {
        if (document.activeElement === document.body)
          for (const P of y)
            P.removedNodes.length > 0 && G(a);
      };
      document.addEventListener("focusin", f), document.addEventListener("focusout", C);
      const x = new MutationObserver(E);
      return a && x.observe(a, { childList: !0, subtree: !0 }), () => {
        document.removeEventListener("focusin", f), document.removeEventListener("focusout", C), x.disconnect();
      };
    }
  }, [r, a, h.paused]), o.useEffect(() => {
    if (a) {
      nt.add(h);
      const f = document.activeElement;
      if (!a.contains(f)) {
        const E = new CustomEvent(Me, et);
        a.addEventListener(Me, u), a.dispatchEvent(E), E.defaultPrevented || (rr(ir(vt(a)), { select: !0 }), document.activeElement === f && G(a));
      }
      return () => {
        a.removeEventListener(Me, u), setTimeout(() => {
          const E = new CustomEvent(_e, et);
          a.addEventListener(_e, v), a.dispatchEvent(E), E.defaultPrevented || G(f ?? document.body, { select: !0 }), a.removeEventListener(_e, v), nt.remove(h);
        }, 0);
      };
    }
  }, [a, u, v, h]);
  const p = o.useCallback(
    (f) => {
      if (!n && !r || h.paused) return;
      const C = f.key === "Tab" && !f.altKey && !f.ctrlKey && !f.metaKey, E = document.activeElement;
      if (C && E) {
        const x = f.currentTarget, [y, w] = or(x);
        y && w ? !f.shiftKey && E === w ? (f.preventDefault(), n && G(y, { select: !0 })) : f.shiftKey && E === y && (f.preventDefault(), n && G(w, { select: !0 })) : E === x && f.preventDefault();
      }
    },
    [n, r, h.paused]
  );
  return /* @__PURE__ */ g.jsx(O.div, { tabIndex: -1, ...i, ref: m, onKeyDown: p });
});
pt.displayName = nr;
function rr(e, { select: t = !1 } = {}) {
  const n = document.activeElement;
  for (const r of e)
    if (G(r, { select: t }), document.activeElement !== n) return;
}
function or(e) {
  const t = vt(e), n = tt(t, e), r = tt(t.reverse(), e);
  return [n, r];
}
function vt(e) {
  const t = [], n = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (r) => {
      const s = r.tagName === "INPUT" && r.type === "hidden";
      return r.disabled || r.hidden || s ? NodeFilter.FILTER_SKIP : r.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; n.nextNode(); ) t.push(n.currentNode);
  return t;
}
function tt(e, t) {
  for (const n of e)
    if (!sr(n, { upTo: t })) return n;
}
function sr(e, { upTo: t }) {
  if (getComputedStyle(e).visibility === "hidden") return !0;
  for (; e; ) {
    if (t !== void 0 && e === t) return !1;
    if (getComputedStyle(e).display === "none") return !0;
    e = e.parentElement;
  }
  return !1;
}
function cr(e) {
  return e instanceof HTMLInputElement && "select" in e;
}
function G(e, { select: t = !1 } = {}) {
  if (e && e.focus) {
    const n = document.activeElement;
    e.focus({ preventScroll: !0 }), e !== n && cr(e) && t && e.select();
  }
}
var nt = ar();
function ar() {
  let e = [];
  return {
    add(t) {
      const n = e[0];
      t !== n && (n == null || n.pause()), e = rt(e, t), e.unshift(t);
    },
    remove(t) {
      var n;
      e = rt(e, t), (n = e[0]) == null || n.resume();
    }
  };
}
function rt(e, t) {
  const n = [...e], r = n.indexOf(t);
  return r !== -1 && n.splice(r, 1), n;
}
function ir(e) {
  return e.filter((t) => t.tagName !== "A");
}
var z = globalThis != null && globalThis.document ? o.useLayoutEffect : () => {
}, ur = o.useId || (() => {
}), lr = 0;
function Oe(e) {
  const [t, n] = o.useState(ur());
  return z(() => {
    n((r) => r ?? String(lr++));
  }, [e]), t ? `radix-${t}` : "";
}
var dr = "Arrow", ht = o.forwardRef((e, t) => {
  const { children: n, width: r = 10, height: s = 5, ...c } = e;
  return /* @__PURE__ */ g.jsx(
    O.svg,
    {
      ...c,
      ref: t,
      width: r,
      height: s,
      viewBox: "0 0 30 10",
      preserveAspectRatio: "none",
      children: e.asChild ? n : /* @__PURE__ */ g.jsx("polygon", { points: "0,0 30,0 15,10" })
    }
  );
});
ht.displayName = dr;
var fr = ht;
function mr(e, t = []) {
  let n = [];
  function r(c, i) {
    const a = o.createContext(i), d = n.length;
    n = [...n, i];
    function u(l) {
      const { scope: m, children: h, ...p } = l, f = (m == null ? void 0 : m[e][d]) || a, C = o.useMemo(() => p, Object.values(p));
      return /* @__PURE__ */ g.jsx(f.Provider, { value: C, children: h });
    }
    function v(l, m) {
      const h = (m == null ? void 0 : m[e][d]) || a, p = o.useContext(h);
      if (p) return p;
      if (i !== void 0) return i;
      throw new Error(`\`${l}\` must be used within \`${c}\``);
    }
    return u.displayName = c + "Provider", [u, v];
  }
  const s = () => {
    const c = n.map((i) => o.createContext(i));
    return function(a) {
      const d = (a == null ? void 0 : a[e]) || c;
      return o.useMemo(
        () => ({ [`__scope${e}`]: { ...a, [e]: d } }),
        [a, d]
      );
    };
  };
  return s.scopeName = e, [r, pr(s, ...t)];
}
function pr(...e) {
  const t = e[0];
  if (e.length === 1) return t;
  const n = () => {
    const r = e.map((s) => ({
      useScope: s(),
      scopeName: s.scopeName
    }));
    return function(c) {
      const i = r.reduce((a, { useScope: d, scopeName: u }) => {
        const l = d(c)[`__scope${u}`];
        return { ...a, ...l };
      }, {});
      return o.useMemo(() => ({ [`__scope${t.scopeName}`]: i }), [i]);
    };
  };
  return n.scopeName = t.scopeName, n;
}
function vr(e) {
  const [t, n] = o.useState(void 0);
  return z(() => {
    if (e) {
      n({ width: e.offsetWidth, height: e.offsetHeight });
      const r = new ResizeObserver((s) => {
        if (!Array.isArray(s) || !s.length)
          return;
        const c = s[0];
        let i, a;
        if ("borderBoxSize" in c) {
          const d = c.borderBoxSize, u = Array.isArray(d) ? d[0] : d;
          i = u.inlineSize, a = u.blockSize;
        } else
          i = e.offsetWidth, a = e.offsetHeight;
        n({ width: i, height: a });
      });
      return r.observe(e, { box: "border-box" }), () => r.unobserve(e);
    } else
      n(void 0);
  }, [e]), t;
}
var je = "Popper", [gt, Ct] = mr(je), [hr, Et] = gt(je), xt = (e) => {
  const { __scopePopper: t, children: n } = e, [r, s] = o.useState(null);
  return /* @__PURE__ */ g.jsx(hr, { scope: t, anchor: r, onAnchorChange: s, children: n });
};
xt.displayName = je;
var St = "PopperAnchor", yt = o.forwardRef(
  (e, t) => {
    const { __scopePopper: n, virtualRef: r, ...s } = e, c = Et(St, n), i = o.useRef(null), a = T(t, i);
    return o.useEffect(() => {
      c.onAnchorChange((r == null ? void 0 : r.current) || i.current);
    }), r ? null : /* @__PURE__ */ g.jsx(O.div, { ...s, ref: a });
  }
);
yt.displayName = St;
var $e = "PopperContent", [gr, Cr] = gt($e), wt = o.forwardRef(
  (e, t) => {
    var K, se, L, ce, Xe, ze;
    const {
      __scopePopper: n,
      side: r = "bottom",
      sideOffset: s = 0,
      align: c = "center",
      alignOffset: i = 0,
      arrowPadding: a = 0,
      avoidCollisions: d = !0,
      collisionBoundary: u = [],
      collisionPadding: v = 0,
      sticky: l = "partial",
      hideWhenDetached: m = !1,
      updatePositionStrategy: h = "optimized",
      onPlaced: p,
      ...f
    } = e, C = Et($e, n), [E, x] = o.useState(null), y = T(t, (ae) => x(ae)), [w, P] = o.useState(null), _ = vr(w), b = (_ == null ? void 0 : _.width) ?? 0, M = (_ == null ? void 0 : _.height) ?? 0, A = r + (c !== "center" ? "-" + c : ""), F = typeof v == "number" ? v : { top: 0, right: 0, bottom: 0, left: 0, ...v }, W = Array.isArray(u) ? u : [u], Y = W.length > 0, B = {
      padding: F,
      boundary: W.filter(xr),
      // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
      altBoundary: Y
    }, { refs: q, floatingStyles: Q, placement: $, isPositioned: J, middlewareData: D } = gn({
      // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
      strategy: "fixed",
      placement: A,
      whileElementsMounted: (...ae) => Pn(...ae, {
        animationFrame: h === "always"
      }),
      elements: {
        reference: C.anchor
      },
      middleware: [
        Cn({ mainAxis: s + M, alignmentAxis: i }),
        d && En({
          mainAxis: !0,
          crossAxis: !1,
          limiter: l === "partial" ? bn() : void 0,
          ...B
        }),
        d && xn({ ...B }),
        Sn({
          ...B,
          apply: ({ elements: ae, rects: Ze, availableWidth: dn, availableHeight: fn }) => {
            const { width: mn, height: pn } = Ze.reference, ge = ae.floating.style;
            ge.setProperty("--radix-popper-available-width", `${dn}px`), ge.setProperty("--radix-popper-available-height", `${fn}px`), ge.setProperty("--radix-popper-anchor-width", `${mn}px`), ge.setProperty("--radix-popper-anchor-height", `${pn}px`);
          }
        }),
        w && yn({ element: w, padding: a }),
        Sr({ arrowWidth: b, arrowHeight: M }),
        m && wn({ strategy: "referenceHidden", ...B })
      ]
    }), [S, N] = Rt($), I = j(p);
    z(() => {
      J && (I == null || I());
    }, [J, I]);
    const U = (K = D.arrow) == null ? void 0 : K.x, re = (se = D.arrow) == null ? void 0 : se.y, oe = ((L = D.arrow) == null ? void 0 : L.centerOffset) !== 0, [he, V] = o.useState();
    return z(() => {
      E && V(window.getComputedStyle(E).zIndex);
    }, [E]), /* @__PURE__ */ g.jsx(
      "div",
      {
        ref: q.setFloating,
        "data-radix-popper-content-wrapper": "",
        style: {
          ...Q,
          transform: J ? Q.transform : "translate(0, -200%)",
          // keep off the page when measuring
          minWidth: "max-content",
          zIndex: he,
          "--radix-popper-transform-origin": [
            (ce = D.transformOrigin) == null ? void 0 : ce.x,
            (Xe = D.transformOrigin) == null ? void 0 : Xe.y
          ].join(" "),
          // hide the content if using the hide middleware and should be hidden
          // set visibility to hidden and disable pointer events so the UI behaves
          // as if the PopperContent isn't there at all
          ...((ze = D.hide) == null ? void 0 : ze.referenceHidden) && {
            visibility: "hidden",
            pointerEvents: "none"
          }
        },
        dir: e.dir,
        children: /* @__PURE__ */ g.jsx(
          gr,
          {
            scope: n,
            placedSide: S,
            onArrowChange: P,
            arrowX: U,
            arrowY: re,
            shouldHideArrow: oe,
            children: /* @__PURE__ */ g.jsx(
              O.div,
              {
                "data-side": S,
                "data-align": N,
                ...f,
                ref: y,
                style: {
                  ...f.style,
                  // if the PopperContent hasn't been placed yet (not all measurements done)
                  // we prevent animations so that users's animation don't kick in too early referring wrong sides
                  animation: J ? void 0 : "none"
                }
              }
            )
          }
        )
      }
    );
  }
);
wt.displayName = $e;
var bt = "PopperArrow", Er = {
  top: "bottom",
  right: "left",
  bottom: "top",
  left: "right"
}, Pt = o.forwardRef(function(t, n) {
  const { __scopePopper: r, ...s } = t, c = Cr(bt, r), i = Er[c.placedSide];
  return (
    // we have to use an extra wrapper because `ResizeObserver` (used by `useSize`)
    // doesn't report size as we'd expect on SVG elements.
    // it reports their bounding box which is effectively the largest path inside the SVG.
    /* @__PURE__ */ g.jsx(
      "span",
      {
        ref: c.onArrowChange,
        style: {
          position: "absolute",
          left: c.arrowX,
          top: c.arrowY,
          [i]: 0,
          transformOrigin: {
            top: "",
            right: "0 0",
            bottom: "center 0",
            left: "100% 0"
          }[c.placedSide],
          transform: {
            top: "translateY(100%)",
            right: "translateY(50%) rotate(90deg) translateX(-50%)",
            bottom: "rotate(180deg)",
            left: "translateY(50%) rotate(-90deg) translateX(50%)"
          }[c.placedSide],
          visibility: c.shouldHideArrow ? "hidden" : void 0
        },
        children: /* @__PURE__ */ g.jsx(
          fr,
          {
            ...s,
            ref: n,
            style: {
              ...s.style,
              // ensures the element can be measured correctly (mostly for if SVG)
              display: "block"
            }
          }
        )
      }
    )
  );
});
Pt.displayName = bt;
function xr(e) {
  return e !== null;
}
var Sr = (e) => ({
  name: "transformOrigin",
  options: e,
  fn(t) {
    var C, E, x;
    const { placement: n, rects: r, middlewareData: s } = t, i = ((C = s.arrow) == null ? void 0 : C.centerOffset) !== 0, a = i ? 0 : e.arrowWidth, d = i ? 0 : e.arrowHeight, [u, v] = Rt(n), l = { start: "0%", center: "50%", end: "100%" }[v], m = (((E = s.arrow) == null ? void 0 : E.x) ?? 0) + a / 2, h = (((x = s.arrow) == null ? void 0 : x.y) ?? 0) + d / 2;
    let p = "", f = "";
    return u === "bottom" ? (p = i ? l : `${m}px`, f = `${-d}px`) : u === "top" ? (p = i ? l : `${m}px`, f = `${r.floating.height + d}px`) : u === "right" ? (p = `${-d}px`, f = i ? l : `${h}px`) : u === "left" && (p = `${r.floating.width + d}px`, f = i ? l : `${h}px`), { data: { x: p, y: f } };
  }
});
function Rt(e) {
  const [t, n = "center"] = e.split("-");
  return [t, n];
}
var Mt = xt, yr = yt, wr = wt, br = Pt, Pr = "Portal", _t = o.forwardRef((e, t) => {
  var a;
  const { container: n, ...r } = e, [s, c] = o.useState(!1);
  z(() => c(!0), []);
  const i = n || s && ((a = globalThis == null ? void 0 : globalThis.document) == null ? void 0 : a.body);
  return i ? hn.createPortal(/* @__PURE__ */ g.jsx(O.div, { ...r, ref: t }), i) : null;
});
_t.displayName = Pr;
function Rr(e, t) {
  return o.useReducer((n, r) => t[n][r] ?? n, e);
}
var fe = (e) => {
  const { present: t, children: n } = e, r = Mr(t), s = typeof n == "function" ? n({ present: r.isPresent }) : o.Children.only(n), c = T(r.ref, _r(s));
  return typeof n == "function" || r.isPresent ? o.cloneElement(s, { ref: c }) : null;
};
fe.displayName = "Presence";
function Mr(e) {
  const [t, n] = o.useState(), r = o.useRef({}), s = o.useRef(e), c = o.useRef("none"), i = e ? "mounted" : "unmounted", [a, d] = Rr(i, {
    mounted: {
      UNMOUNT: "unmounted",
      ANIMATION_OUT: "unmountSuspended"
    },
    unmountSuspended: {
      MOUNT: "mounted",
      ANIMATION_END: "unmounted"
    },
    unmounted: {
      MOUNT: "mounted"
    }
  });
  return o.useEffect(() => {
    const u = Ce(r.current);
    c.current = a === "mounted" ? u : "none";
  }, [a]), z(() => {
    const u = r.current, v = s.current;
    if (v !== e) {
      const m = c.current, h = Ce(u);
      e ? d("MOUNT") : h === "none" || (u == null ? void 0 : u.display) === "none" ? d("UNMOUNT") : d(v && m !== h ? "ANIMATION_OUT" : "UNMOUNT"), s.current = e;
    }
  }, [e, d]), z(() => {
    if (t) {
      let u;
      const v = t.ownerDocument.defaultView ?? window, l = (h) => {
        const f = Ce(r.current).includes(h.animationName);
        if (h.target === t && f && (d("ANIMATION_END"), !s.current)) {
          const C = t.style.animationFillMode;
          t.style.animationFillMode = "forwards", u = v.setTimeout(() => {
            t.style.animationFillMode === "forwards" && (t.style.animationFillMode = C);
          });
        }
      }, m = (h) => {
        h.target === t && (c.current = Ce(r.current));
      };
      return t.addEventListener("animationstart", m), t.addEventListener("animationcancel", l), t.addEventListener("animationend", l), () => {
        v.clearTimeout(u), t.removeEventListener("animationstart", m), t.removeEventListener("animationcancel", l), t.removeEventListener("animationend", l);
      };
    } else
      d("ANIMATION_END");
  }, [t, d]), {
    isPresent: ["mounted", "unmountSuspended"].includes(a),
    ref: o.useCallback((u) => {
      u && (r.current = getComputedStyle(u)), n(u);
    }, [])
  };
}
function Ce(e) {
  return (e == null ? void 0 : e.animationName) || "none";
}
function _r(e) {
  var r, s;
  let t = (r = Object.getOwnPropertyDescriptor(e.props, "ref")) == null ? void 0 : r.get, n = t && "isReactWarning" in t && t.isReactWarning;
  return n ? e.ref : (t = (s = Object.getOwnPropertyDescriptor(e, "ref")) == null ? void 0 : s.get, n = t && "isReactWarning" in t && t.isReactWarning, n ? e.props.ref : e.props.ref || e.ref);
}
function Ir(e, t = []) {
  let n = [];
  function r(c, i) {
    const a = o.createContext(i), d = n.length;
    n = [...n, i];
    function u(l) {
      const { scope: m, children: h, ...p } = l, f = (m == null ? void 0 : m[e][d]) || a, C = o.useMemo(() => p, Object.values(p));
      return /* @__PURE__ */ g.jsx(f.Provider, { value: C, children: h });
    }
    function v(l, m) {
      const h = (m == null ? void 0 : m[e][d]) || a, p = o.useContext(h);
      if (p) return p;
      if (i !== void 0) return i;
      throw new Error(`\`${l}\` must be used within \`${c}\``);
    }
    return u.displayName = c + "Provider", [u, v];
  }
  const s = () => {
    const c = n.map((i) => o.createContext(i));
    return function(a) {
      const d = (a == null ? void 0 : a[e]) || c;
      return o.useMemo(
        () => ({ [`__scope${e}`]: { ...a, [e]: d } }),
        [a, d]
      );
    };
  };
  return s.scopeName = e, [r, Ar(s, ...t)];
}
function Ar(...e) {
  const t = e[0];
  if (e.length === 1) return t;
  const n = () => {
    const r = e.map((s) => ({
      useScope: s(),
      scopeName: s.scopeName
    }));
    return function(c) {
      const i = r.reduce((a, { useScope: d, scopeName: u }) => {
        const l = d(c)[`__scope${u}`];
        return { ...a, ...l };
      }, {});
      return o.useMemo(() => ({ [`__scope${t.scopeName}`]: i }), [i]);
    };
  };
  return n.scopeName = t.scopeName, n;
}
function Nr({
  prop: e,
  defaultProp: t,
  onChange: n = () => {
  }
}) {
  const [r, s] = Tr({ defaultProp: t, onChange: n }), c = e !== void 0, i = c ? e : r, a = j(n), d = o.useCallback(
    (u) => {
      if (c) {
        const l = typeof u == "function" ? u(e) : u;
        l !== e && a(l);
      } else
        s(u);
    },
    [c, e, s, a]
  );
  return [i, d];
}
function Tr({
  defaultProp: e,
  onChange: t
}) {
  const n = o.useState(e), [r] = n, s = o.useRef(r), c = j(t);
  return o.useEffect(() => {
    s.current !== r && (c(r), s.current = r);
  }, [r, s, c]), n;
}
var Ie = "rovingFocusGroup.onEntryFocus", Or = { bubbles: !1, cancelable: !0 }, we = "RovingFocusGroup", [Fe, It, Fr] = it(we), [Dr, At] = Ir(
  we,
  [Fr]
), [Lr, kr] = Dr(we), Nt = o.forwardRef(
  (e, t) => /* @__PURE__ */ g.jsx(Fe.Provider, { scope: e.__scopeRovingFocusGroup, children: /* @__PURE__ */ g.jsx(Fe.Slot, { scope: e.__scopeRovingFocusGroup, children: /* @__PURE__ */ g.jsx(jr, { ...e, ref: t }) }) })
);
Nt.displayName = we;
var jr = o.forwardRef((e, t) => {
  const {
    __scopeRovingFocusGroup: n,
    orientation: r,
    loop: s = !1,
    dir: c,
    currentTabStopId: i,
    defaultCurrentTabStopId: a,
    onCurrentTabStopIdChange: d,
    onEntryFocus: u,
    preventScrollOnEntryFocus: v = !1,
    ...l
  } = e, m = o.useRef(null), h = T(t, m), p = ut(c), [f = null, C] = Nr({
    prop: i,
    defaultProp: a,
    onChange: d
  }), [E, x] = o.useState(!1), y = j(u), w = It(n), P = o.useRef(!1), [_, b] = o.useState(0);
  return o.useEffect(() => {
    const M = m.current;
    if (M)
      return M.addEventListener(Ie, y), () => M.removeEventListener(Ie, y);
  }, [y]), /* @__PURE__ */ g.jsx(
    Lr,
    {
      scope: n,
      orientation: r,
      dir: p,
      loop: s,
      currentTabStopId: f,
      onItemFocus: o.useCallback(
        (M) => C(M),
        [C]
      ),
      onItemShiftTab: o.useCallback(() => x(!0), []),
      onFocusableItemAdd: o.useCallback(
        () => b((M) => M + 1),
        []
      ),
      onFocusableItemRemove: o.useCallback(
        () => b((M) => M - 1),
        []
      ),
      children: /* @__PURE__ */ g.jsx(
        O.div,
        {
          tabIndex: E || _ === 0 ? -1 : 0,
          "data-orientation": r,
          ...l,
          ref: h,
          style: { outline: "none", ...e.style },
          onMouseDown: R(e.onMouseDown, () => {
            P.current = !0;
          }),
          onFocus: R(e.onFocus, (M) => {
            const A = !P.current;
            if (M.target === M.currentTarget && A && !E) {
              const F = new CustomEvent(Ie, Or);
              if (M.currentTarget.dispatchEvent(F), !F.defaultPrevented) {
                const W = w().filter(($) => $.focusable), Y = W.find(($) => $.active), B = W.find(($) => $.id === f), Q = [Y, B, ...W].filter(
                  Boolean
                ).map(($) => $.ref.current);
                Ft(Q, v);
              }
            }
            P.current = !1;
          }),
          onBlur: R(e.onBlur, () => x(!1))
        }
      )
    }
  );
}), Tt = "RovingFocusGroupItem", Ot = o.forwardRef(
  (e, t) => {
    const {
      __scopeRovingFocusGroup: n,
      focusable: r = !0,
      active: s = !1,
      tabStopId: c,
      ...i
    } = e, a = Oe(), d = c || a, u = kr(Tt, n), v = u.currentTabStopId === d, l = It(n), { onFocusableItemAdd: m, onFocusableItemRemove: h } = u;
    return o.useEffect(() => {
      if (r)
        return m(), () => h();
    }, [r, m, h]), /* @__PURE__ */ g.jsx(
      Fe.ItemSlot,
      {
        scope: n,
        id: d,
        focusable: r,
        active: s,
        children: /* @__PURE__ */ g.jsx(
          O.span,
          {
            tabIndex: v ? 0 : -1,
            "data-orientation": u.orientation,
            ...i,
            ref: t,
            onMouseDown: R(e.onMouseDown, (p) => {
              r ? u.onItemFocus(d) : p.preventDefault();
            }),
            onFocus: R(e.onFocus, () => u.onItemFocus(d)),
            onKeyDown: R(e.onKeyDown, (p) => {
              if (p.key === "Tab" && p.shiftKey) {
                u.onItemShiftTab();
                return;
              }
              if (p.target !== p.currentTarget) return;
              const f = Br(p, u.orientation, u.dir);
              if (f !== void 0) {
                if (p.metaKey || p.ctrlKey || p.altKey || p.shiftKey) return;
                p.preventDefault();
                let E = l().filter((x) => x.focusable).map((x) => x.ref.current);
                if (f === "last") E.reverse();
                else if (f === "prev" || f === "next") {
                  f === "prev" && E.reverse();
                  const x = E.indexOf(p.currentTarget);
                  E = u.loop ? Kr(E, x + 1) : E.slice(x + 1);
                }
                setTimeout(() => Ft(E));
              }
            })
          }
        )
      }
    );
  }
);
Ot.displayName = Tt;
var $r = {
  ArrowLeft: "prev",
  ArrowUp: "prev",
  ArrowRight: "next",
  ArrowDown: "next",
  PageUp: "first",
  Home: "first",
  PageDown: "last",
  End: "last"
};
function Ur(e, t) {
  return t !== "rtl" ? e : e === "ArrowLeft" ? "ArrowRight" : e === "ArrowRight" ? "ArrowLeft" : e;
}
function Br(e, t, n) {
  const r = Ur(e.key, n);
  if (!(t === "vertical" && ["ArrowLeft", "ArrowRight"].includes(r)) && !(t === "horizontal" && ["ArrowUp", "ArrowDown"].includes(r)))
    return $r[r];
}
function Ft(e, t = !1) {
  const n = document.activeElement;
  for (const r of e)
    if (r === n || (r.focus({ preventScroll: t }), document.activeElement !== n)) return;
}
function Kr(e, t) {
  return e.map((n, r) => e[(t + r) % e.length]);
}
var Wr = Nt, Gr = Ot, Dt = In(), Ae = function() {
}, be = o.forwardRef(function(e, t) {
  var n = o.useRef(null), r = o.useState({
    onScrollCapture: Ae,
    onWheelCapture: Ae,
    onTouchMoveCapture: Ae
  }), s = r[0], c = r[1], i = e.forwardProps, a = e.children, d = e.className, u = e.removeScrollBar, v = e.enabled, l = e.shards, m = e.sideCar, h = e.noIsolation, p = e.inert, f = e.allowPinchZoom, C = e.as, E = C === void 0 ? "div" : C, x = e.gapMode, y = Mn(e, ["forwardProps", "children", "className", "removeScrollBar", "enabled", "shards", "sideCar", "noIsolation", "inert", "allowPinchZoom", "as", "gapMode"]), w = m, P = An([n, t]), _ = ne(ne({}, y), s);
  return o.createElement(
    o.Fragment,
    null,
    v && o.createElement(w, { sideCar: Dt, removeScrollBar: u, shards: l, noIsolation: h, inert: p, setCallbacks: c, allowPinchZoom: !!f, lockRef: n, gapMode: x }),
    i ? o.cloneElement(o.Children.only(a), ne(ne({}, _), { ref: P })) : o.createElement(E, ne({}, _, { className: d, ref: P }), a)
  );
});
be.defaultProps = {
  enabled: !0,
  removeScrollBar: !0,
  inert: !1
};
be.classNames = {
  fullWidth: Tn,
  zeroRight: Nn
};
var De = !1;
if (typeof window < "u")
  try {
    var Ee = Object.defineProperty({}, "passive", {
      get: function() {
        return De = !0, !0;
      }
    });
    window.addEventListener("test", Ee, Ee), window.removeEventListener("test", Ee, Ee);
  } catch {
    De = !1;
  }
var ee = De ? { passive: !1 } : !1, Hr = function(e) {
  return e.tagName === "TEXTAREA";
}, Lt = function(e, t) {
  if (!(e instanceof Element))
    return !1;
  var n = window.getComputedStyle(e);
  return (
    // not-not-scrollable
    n[t] !== "hidden" && // contains scroll inside self
    !(n.overflowY === n.overflowX && !Hr(e) && n[t] === "visible")
  );
}, Yr = function(e) {
  return Lt(e, "overflowY");
}, Vr = function(e) {
  return Lt(e, "overflowX");
}, ot = function(e, t) {
  var n = t.ownerDocument, r = t;
  do {
    typeof ShadowRoot < "u" && r instanceof ShadowRoot && (r = r.host);
    var s = kt(e, r);
    if (s) {
      var c = jt(e, r), i = c[1], a = c[2];
      if (i > a)
        return !0;
    }
    r = r.parentNode;
  } while (r && r !== n.body);
  return !1;
}, Xr = function(e) {
  var t = e.scrollTop, n = e.scrollHeight, r = e.clientHeight;
  return [
    t,
    n,
    r
  ];
}, zr = function(e) {
  var t = e.scrollLeft, n = e.scrollWidth, r = e.clientWidth;
  return [
    t,
    n,
    r
  ];
}, kt = function(e, t) {
  return e === "v" ? Yr(t) : Vr(t);
}, jt = function(e, t) {
  return e === "v" ? Xr(t) : zr(t);
}, Zr = function(e, t) {
  return e === "h" && t === "rtl" ? -1 : 1;
}, qr = function(e, t, n, r, s) {
  var c = Zr(e, window.getComputedStyle(t).direction), i = c * r, a = n.target, d = t.contains(a), u = !1, v = i > 0, l = 0, m = 0;
  do {
    var h = jt(e, a), p = h[0], f = h[1], C = h[2], E = f - C - c * p;
    (p || E) && kt(e, a) && (l += E, m += p), a instanceof ShadowRoot ? a = a.host : a = a.parentNode;
  } while (
    // portaled content
    !d && a !== document.body || // self content
    d && (t.contains(a) || t === a)
  );
  return (v && Math.abs(l) < 1 || !v && Math.abs(m) < 1) && (u = !0), u;
}, xe = function(e) {
  return "changedTouches" in e ? [e.changedTouches[0].clientX, e.changedTouches[0].clientY] : [0, 0];
}, st = function(e) {
  return [e.deltaX, e.deltaY];
}, ct = function(e) {
  return e && "current" in e ? e.current : e;
}, Qr = function(e, t) {
  return e[0] === t[0] && e[1] === t[1];
}, Jr = function(e) {
  return `
  .block-interactivity-`.concat(e, ` {pointer-events: none;}
  .allow-interactivity-`).concat(e, ` {pointer-events: all;}
`);
}, eo = 0, te = [];
function to(e) {
  var t = o.useRef([]), n = o.useRef([0, 0]), r = o.useRef(), s = o.useState(eo++)[0], c = o.useState(On)[0], i = o.useRef(e);
  o.useEffect(function() {
    i.current = e;
  }, [e]), o.useEffect(function() {
    if (e.inert) {
      document.body.classList.add("block-interactivity-".concat(s));
      var f = _n([e.lockRef.current], (e.shards || []).map(ct), !0).filter(Boolean);
      return f.forEach(function(C) {
        return C.classList.add("allow-interactivity-".concat(s));
      }), function() {
        document.body.classList.remove("block-interactivity-".concat(s)), f.forEach(function(C) {
          return C.classList.remove("allow-interactivity-".concat(s));
        });
      };
    }
  }, [e.inert, e.lockRef.current, e.shards]);
  var a = o.useCallback(function(f, C) {
    if ("touches" in f && f.touches.length === 2 || f.type === "wheel" && f.ctrlKey)
      return !i.current.allowPinchZoom;
    var E = xe(f), x = n.current, y = "deltaX" in f ? f.deltaX : x[0] - E[0], w = "deltaY" in f ? f.deltaY : x[1] - E[1], P, _ = f.target, b = Math.abs(y) > Math.abs(w) ? "h" : "v";
    if ("touches" in f && b === "h" && _.type === "range")
      return !1;
    var M = ot(b, _);
    if (!M)
      return !0;
    if (M ? P = b : (P = b === "v" ? "h" : "v", M = ot(b, _)), !M)
      return !1;
    if (!r.current && "changedTouches" in f && (y || w) && (r.current = P), !P)
      return !0;
    var A = r.current || P;
    return qr(A, C, f, A === "h" ? y : w);
  }, []), d = o.useCallback(function(f) {
    var C = f;
    if (!(!te.length || te[te.length - 1] !== c)) {
      var E = "deltaY" in C ? st(C) : xe(C), x = t.current.filter(function(P) {
        return P.name === C.type && (P.target === C.target || C.target === P.shadowParent) && Qr(P.delta, E);
      })[0];
      if (x && x.should) {
        C.cancelable && C.preventDefault();
        return;
      }
      if (!x) {
        var y = (i.current.shards || []).map(ct).filter(Boolean).filter(function(P) {
          return P.contains(C.target);
        }), w = y.length > 0 ? a(C, y[0]) : !i.current.noIsolation;
        w && C.cancelable && C.preventDefault();
      }
    }
  }, []), u = o.useCallback(function(f, C, E, x) {
    var y = { name: f, delta: C, target: E, should: x, shadowParent: no(E) };
    t.current.push(y), setTimeout(function() {
      t.current = t.current.filter(function(w) {
        return w !== y;
      });
    }, 1);
  }, []), v = o.useCallback(function(f) {
    n.current = xe(f), r.current = void 0;
  }, []), l = o.useCallback(function(f) {
    u(f.type, st(f), f.target, a(f, e.lockRef.current));
  }, []), m = o.useCallback(function(f) {
    u(f.type, xe(f), f.target, a(f, e.lockRef.current));
  }, []);
  o.useEffect(function() {
    return te.push(c), e.setCallbacks({
      onScrollCapture: l,
      onWheelCapture: l,
      onTouchMoveCapture: m
    }), document.addEventListener("wheel", d, ee), document.addEventListener("touchmove", d, ee), document.addEventListener("touchstart", v, ee), function() {
      te = te.filter(function(f) {
        return f !== c;
      }), document.removeEventListener("wheel", d, ee), document.removeEventListener("touchmove", d, ee), document.removeEventListener("touchstart", v, ee);
    };
  }, []);
  var h = e.removeScrollBar, p = e.inert;
  return o.createElement(
    o.Fragment,
    null,
    p ? o.createElement(c, { styles: Jr(s) }) : null,
    h ? o.createElement(Fn, { gapMode: e.gapMode }) : null
  );
}
function no(e) {
  for (var t = null; e !== null; )
    e instanceof ShadowRoot && (t = e.host, e = e.host), e = e.parentNode;
  return t;
}
const ro = Dn(Dt, to);
var $t = o.forwardRef(function(e, t) {
  return o.createElement(be, ne({}, e, { ref: t, sideCar: ro }));
});
$t.classNames = be.classNames;
var Le = ["Enter", " "], oo = ["ArrowDown", "PageUp", "Home"], Ut = ["ArrowUp", "PageDown", "End"], so = [...oo, ...Ut], co = {
  ltr: [...Le, "ArrowRight"],
  rtl: [...Le, "ArrowLeft"]
}, ao = {
  ltr: ["ArrowLeft"],
  rtl: ["ArrowRight"]
}, me = "Menu", [le, io, uo] = it(me), [Z, jo] = Wn(me, [
  uo,
  Ct,
  At
]), pe = Ct(), Bt = At(), [Kt, H] = Z(me), [lo, ve] = Z(me), Wt = (e) => {
  const { __scopeMenu: t, open: n = !1, children: r, dir: s, onOpenChange: c, modal: i = !0 } = e, a = pe(t), [d, u] = o.useState(null), v = o.useRef(!1), l = j(c), m = ut(s);
  return o.useEffect(() => {
    const h = () => {
      v.current = !0, document.addEventListener("pointerdown", p, { capture: !0, once: !0 }), document.addEventListener("pointermove", p, { capture: !0, once: !0 });
    }, p = () => v.current = !1;
    return document.addEventListener("keydown", h, { capture: !0 }), () => {
      document.removeEventListener("keydown", h, { capture: !0 }), document.removeEventListener("pointerdown", p, { capture: !0 }), document.removeEventListener("pointermove", p, { capture: !0 });
    };
  }, []), /* @__PURE__ */ g.jsx(Mt, { ...a, children: /* @__PURE__ */ g.jsx(
    Kt,
    {
      scope: t,
      open: n,
      onOpenChange: l,
      content: d,
      onContentChange: u,
      children: /* @__PURE__ */ g.jsx(
        lo,
        {
          scope: t,
          onClose: o.useCallback(() => l(!1), [l]),
          isUsingKeyboardRef: v,
          dir: m,
          modal: i,
          children: r
        }
      )
    }
  ) });
};
Wt.displayName = me;
var fo = "MenuAnchor", Ue = o.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, ...r } = e, s = pe(n);
    return /* @__PURE__ */ g.jsx(yr, { ...s, ...r, ref: t });
  }
);
Ue.displayName = fo;
var Be = "MenuPortal", [mo, Gt] = Z(Be, {
  forceMount: void 0
}), Ht = (e) => {
  const { __scopeMenu: t, forceMount: n, children: r, container: s } = e, c = H(Be, t);
  return /* @__PURE__ */ g.jsx(mo, { scope: t, forceMount: n, children: /* @__PURE__ */ g.jsx(fe, { present: n || c.open, children: /* @__PURE__ */ g.jsx(_t, { asChild: !0, container: s, children: r }) }) });
};
Ht.displayName = Be;
var k = "MenuContent", [po, Ke] = Z(k), Yt = o.forwardRef(
  (e, t) => {
    const n = Gt(k, e.__scopeMenu), { forceMount: r = n.forceMount, ...s } = e, c = H(k, e.__scopeMenu), i = ve(k, e.__scopeMenu);
    return /* @__PURE__ */ g.jsx(le.Provider, { scope: e.__scopeMenu, children: /* @__PURE__ */ g.jsx(fe, { present: r || c.open, children: /* @__PURE__ */ g.jsx(le.Slot, { scope: e.__scopeMenu, children: i.modal ? /* @__PURE__ */ g.jsx(vo, { ...s, ref: t }) : /* @__PURE__ */ g.jsx(ho, { ...s, ref: t }) }) }) });
  }
), vo = o.forwardRef(
  (e, t) => {
    const n = H(k, e.__scopeMenu), r = o.useRef(null), s = T(t, r);
    return o.useEffect(() => {
      const c = r.current;
      if (c) return Rn(c);
    }, []), /* @__PURE__ */ g.jsx(
      We,
      {
        ...e,
        ref: s,
        trapFocus: n.open,
        disableOutsidePointerEvents: n.open,
        disableOutsideScroll: !0,
        onFocusOutside: R(
          e.onFocusOutside,
          (c) => c.preventDefault(),
          { checkForDefaultPrevented: !1 }
        ),
        onDismiss: () => n.onOpenChange(!1)
      }
    );
  }
), ho = o.forwardRef((e, t) => {
  const n = H(k, e.__scopeMenu);
  return /* @__PURE__ */ g.jsx(
    We,
    {
      ...e,
      ref: t,
      trapFocus: !1,
      disableOutsidePointerEvents: !1,
      disableOutsideScroll: !1,
      onDismiss: () => n.onOpenChange(!1)
    }
  );
}), We = o.forwardRef(
  (e, t) => {
    const {
      __scopeMenu: n,
      loop: r = !1,
      trapFocus: s,
      onOpenAutoFocus: c,
      onCloseAutoFocus: i,
      disableOutsidePointerEvents: a,
      onEntryFocus: d,
      onEscapeKeyDown: u,
      onPointerDownOutside: v,
      onFocusOutside: l,
      onInteractOutside: m,
      onDismiss: h,
      disableOutsideScroll: p,
      ...f
    } = e, C = H(k, n), E = ve(k, n), x = pe(n), y = Bt(n), w = io(n), [P, _] = o.useState(null), b = o.useRef(null), M = T(t, b, C.onContentChange), A = o.useRef(0), F = o.useRef(""), W = o.useRef(0), Y = o.useRef(null), B = o.useRef("right"), q = o.useRef(0), Q = p ? $t : o.Fragment, $ = p ? { as: ue, allowPinchZoom: !0 } : void 0, J = (S) => {
      var K, se;
      const N = F.current + S, I = w().filter((L) => !L.disabled), U = document.activeElement, re = (K = I.find((L) => L.ref.current === U)) == null ? void 0 : K.textValue, oe = I.map((L) => L.textValue), he = _o(oe, N, re), V = (se = I.find((L) => L.textValue === he)) == null ? void 0 : se.ref.current;
      (function L(ce) {
        F.current = ce, window.clearTimeout(A.current), ce !== "" && (A.current = window.setTimeout(() => L(""), 1e3));
      })(N), V && setTimeout(() => V.focus());
    };
    o.useEffect(() => () => window.clearTimeout(A.current), []), tr();
    const D = o.useCallback((S) => {
      var I, U;
      return B.current === ((I = Y.current) == null ? void 0 : I.side) && Ao(S, (U = Y.current) == null ? void 0 : U.area);
    }, []);
    return /* @__PURE__ */ g.jsx(
      po,
      {
        scope: n,
        searchRef: F,
        onItemEnter: o.useCallback(
          (S) => {
            D(S) && S.preventDefault();
          },
          [D]
        ),
        onItemLeave: o.useCallback(
          (S) => {
            var N;
            D(S) || ((N = b.current) == null || N.focus(), _(null));
          },
          [D]
        ),
        onTriggerLeave: o.useCallback(
          (S) => {
            D(S) && S.preventDefault();
          },
          [D]
        ),
        pointerGraceTimerRef: W,
        onPointerGraceIntentChange: o.useCallback((S) => {
          Y.current = S;
        }, []),
        children: /* @__PURE__ */ g.jsx(Q, { ...$, children: /* @__PURE__ */ g.jsx(
          pt,
          {
            asChild: !0,
            trapped: s,
            onMountAutoFocus: R(c, (S) => {
              var N;
              S.preventDefault(), (N = b.current) == null || N.focus({ preventScroll: !0 });
            }),
            onUnmountAutoFocus: i,
            children: /* @__PURE__ */ g.jsx(
              ft,
              {
                asChild: !0,
                disableOutsidePointerEvents: a,
                onEscapeKeyDown: u,
                onPointerDownOutside: v,
                onFocusOutside: l,
                onInteractOutside: m,
                onDismiss: h,
                children: /* @__PURE__ */ g.jsx(
                  Wr,
                  {
                    asChild: !0,
                    ...y,
                    dir: E.dir,
                    orientation: "vertical",
                    loop: r,
                    currentTabStopId: P,
                    onCurrentTabStopIdChange: _,
                    onEntryFocus: R(d, (S) => {
                      E.isUsingKeyboardRef.current || S.preventDefault();
                    }),
                    preventScrollOnEntryFocus: !0,
                    children: /* @__PURE__ */ g.jsx(
                      wr,
                      {
                        role: "menu",
                        "aria-orientation": "vertical",
                        "data-state": ln(C.open),
                        "data-radix-menu-content": "",
                        dir: E.dir,
                        ...x,
                        ...f,
                        ref: M,
                        style: { outline: "none", ...f.style },
                        onKeyDown: R(f.onKeyDown, (S) => {
                          const I = S.target.closest("[data-radix-menu-content]") === S.currentTarget, U = S.ctrlKey || S.altKey || S.metaKey, re = S.key.length === 1;
                          I && (S.key === "Tab" && S.preventDefault(), !U && re && J(S.key));
                          const oe = b.current;
                          if (S.target !== oe || !so.includes(S.key)) return;
                          S.preventDefault();
                          const V = w().filter((K) => !K.disabled).map((K) => K.ref.current);
                          Ut.includes(S.key) && V.reverse(), Ro(V);
                        }),
                        onBlur: R(e.onBlur, (S) => {
                          S.currentTarget.contains(S.target) || (window.clearTimeout(A.current), F.current = "");
                        }),
                        onPointerMove: R(
                          e.onPointerMove,
                          de((S) => {
                            const N = S.target, I = q.current !== S.clientX;
                            if (S.currentTarget.contains(N) && I) {
                              const U = S.clientX > q.current ? "right" : "left";
                              B.current = U, q.current = S.clientX;
                            }
                          })
                        )
                      }
                    )
                  }
                )
              }
            )
          }
        ) })
      }
    );
  }
);
Yt.displayName = k;
var go = "MenuGroup", Ge = o.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, ...r } = e;
    return /* @__PURE__ */ g.jsx(O.div, { role: "group", ...r, ref: t });
  }
);
Ge.displayName = go;
var Co = "MenuLabel", Vt = o.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, ...r } = e;
    return /* @__PURE__ */ g.jsx(O.div, { ...r, ref: t });
  }
);
Vt.displayName = Co;
var Se = "MenuItem", at = "menu.itemSelect", Pe = o.forwardRef(
  (e, t) => {
    const { disabled: n = !1, onSelect: r, ...s } = e, c = o.useRef(null), i = ve(Se, e.__scopeMenu), a = Ke(Se, e.__scopeMenu), d = T(t, c), u = o.useRef(!1), v = () => {
      const l = c.current;
      if (!n && l) {
        const m = new CustomEvent(at, { bubbles: !0, cancelable: !0 });
        l.addEventListener(at, (h) => r == null ? void 0 : r(h), { once: !0 }), lt(l, m), m.defaultPrevented ? u.current = !1 : i.onClose();
      }
    };
    return /* @__PURE__ */ g.jsx(
      Xt,
      {
        ...s,
        ref: d,
        disabled: n,
        onClick: R(e.onClick, v),
        onPointerDown: (l) => {
          var m;
          (m = e.onPointerDown) == null || m.call(e, l), u.current = !0;
        },
        onPointerUp: R(e.onPointerUp, (l) => {
          var m;
          u.current || (m = l.currentTarget) == null || m.click();
        }),
        onKeyDown: R(e.onKeyDown, (l) => {
          const m = a.searchRef.current !== "";
          n || m && l.key === " " || Le.includes(l.key) && (l.currentTarget.click(), l.preventDefault());
        })
      }
    );
  }
);
Pe.displayName = Se;
var Xt = o.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, disabled: r = !1, textValue: s, ...c } = e, i = Ke(Se, n), a = Bt(n), d = o.useRef(null), u = T(t, d), [v, l] = o.useState(!1), [m, h] = o.useState("");
    return o.useEffect(() => {
      const p = d.current;
      p && h((p.textContent ?? "").trim());
    }, [c.children]), /* @__PURE__ */ g.jsx(
      le.ItemSlot,
      {
        scope: n,
        disabled: r,
        textValue: s ?? m,
        children: /* @__PURE__ */ g.jsx(Gr, { asChild: !0, ...a, focusable: !r, children: /* @__PURE__ */ g.jsx(
          O.div,
          {
            role: "menuitem",
            "data-highlighted": v ? "" : void 0,
            "aria-disabled": r || void 0,
            "data-disabled": r ? "" : void 0,
            ...c,
            ref: u,
            onPointerMove: R(
              e.onPointerMove,
              de((p) => {
                r ? i.onItemLeave(p) : (i.onItemEnter(p), p.defaultPrevented || p.currentTarget.focus({ preventScroll: !0 }));
              })
            ),
            onPointerLeave: R(
              e.onPointerLeave,
              de((p) => i.onItemLeave(p))
            ),
            onFocus: R(e.onFocus, () => l(!0)),
            onBlur: R(e.onBlur, () => l(!1))
          }
        ) })
      }
    );
  }
), Eo = "MenuCheckboxItem", zt = o.forwardRef(
  (e, t) => {
    const { checked: n = !1, onCheckedChange: r, ...s } = e;
    return /* @__PURE__ */ g.jsx(en, { scope: e.__scopeMenu, checked: n, children: /* @__PURE__ */ g.jsx(
      Pe,
      {
        role: "menuitemcheckbox",
        "aria-checked": ye(n) ? "mixed" : n,
        ...s,
        ref: t,
        "data-state": Ve(n),
        onSelect: R(
          s.onSelect,
          () => r == null ? void 0 : r(ye(n) ? !0 : !n),
          { checkForDefaultPrevented: !1 }
        )
      }
    ) });
  }
);
zt.displayName = Eo;
var Zt = "MenuRadioGroup", [xo, So] = Z(
  Zt,
  { value: void 0, onValueChange: () => {
  } }
), qt = o.forwardRef(
  (e, t) => {
    const { value: n, onValueChange: r, ...s } = e, c = j(r);
    return /* @__PURE__ */ g.jsx(xo, { scope: e.__scopeMenu, value: n, onValueChange: c, children: /* @__PURE__ */ g.jsx(Ge, { ...s, ref: t }) });
  }
);
qt.displayName = Zt;
var Qt = "MenuRadioItem", Jt = o.forwardRef(
  (e, t) => {
    const { value: n, ...r } = e, s = So(Qt, e.__scopeMenu), c = n === s.value;
    return /* @__PURE__ */ g.jsx(en, { scope: e.__scopeMenu, checked: c, children: /* @__PURE__ */ g.jsx(
      Pe,
      {
        role: "menuitemradio",
        "aria-checked": c,
        ...r,
        ref: t,
        "data-state": Ve(c),
        onSelect: R(
          r.onSelect,
          () => {
            var i;
            return (i = s.onValueChange) == null ? void 0 : i.call(s, n);
          },
          { checkForDefaultPrevented: !1 }
        )
      }
    ) });
  }
);
Jt.displayName = Qt;
var He = "MenuItemIndicator", [en, yo] = Z(
  He,
  { checked: !1 }
), tn = o.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, forceMount: r, ...s } = e, c = yo(He, n);
    return /* @__PURE__ */ g.jsx(
      fe,
      {
        present: r || ye(c.checked) || c.checked === !0,
        children: /* @__PURE__ */ g.jsx(
          O.span,
          {
            ...s,
            ref: t,
            "data-state": Ve(c.checked)
          }
        )
      }
    );
  }
);
tn.displayName = He;
var wo = "MenuSeparator", nn = o.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, ...r } = e;
    return /* @__PURE__ */ g.jsx(
      O.div,
      {
        role: "separator",
        "aria-orientation": "horizontal",
        ...r,
        ref: t
      }
    );
  }
);
nn.displayName = wo;
var bo = "MenuArrow", rn = o.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, ...r } = e, s = pe(n);
    return /* @__PURE__ */ g.jsx(br, { ...s, ...r, ref: t });
  }
);
rn.displayName = bo;
var Ye = "MenuSub", [Po, on] = Z(Ye), sn = (e) => {
  const { __scopeMenu: t, children: n, open: r = !1, onOpenChange: s } = e, c = H(Ye, t), i = pe(t), [a, d] = o.useState(null), [u, v] = o.useState(null), l = j(s);
  return o.useEffect(() => (c.open === !1 && l(!1), () => l(!1)), [c.open, l]), /* @__PURE__ */ g.jsx(Mt, { ...i, children: /* @__PURE__ */ g.jsx(
    Kt,
    {
      scope: t,
      open: r,
      onOpenChange: l,
      content: u,
      onContentChange: v,
      children: /* @__PURE__ */ g.jsx(
        Po,
        {
          scope: t,
          contentId: Oe(),
          triggerId: Oe(),
          trigger: a,
          onTriggerChange: d,
          children: n
        }
      )
    }
  ) });
};
sn.displayName = Ye;
var ie = "MenuSubTrigger", cn = o.forwardRef(
  (e, t) => {
    const n = H(ie, e.__scopeMenu), r = ve(ie, e.__scopeMenu), s = on(ie, e.__scopeMenu), c = Ke(ie, e.__scopeMenu), i = o.useRef(null), { pointerGraceTimerRef: a, onPointerGraceIntentChange: d } = c, u = { __scopeMenu: e.__scopeMenu }, v = o.useCallback(() => {
      i.current && window.clearTimeout(i.current), i.current = null;
    }, []);
    return o.useEffect(() => v, [v]), o.useEffect(() => {
      const l = a.current;
      return () => {
        window.clearTimeout(l), d(null);
      };
    }, [a, d]), /* @__PURE__ */ g.jsx(Ue, { asChild: !0, ...u, children: /* @__PURE__ */ g.jsx(
      Xt,
      {
        id: s.triggerId,
        "aria-haspopup": "menu",
        "aria-expanded": n.open,
        "aria-controls": s.contentId,
        "data-state": ln(n.open),
        ...e,
        ref: ke(t, s.onTriggerChange),
        onClick: (l) => {
          var m;
          (m = e.onClick) == null || m.call(e, l), !(e.disabled || l.defaultPrevented) && (l.currentTarget.focus(), n.open || n.onOpenChange(!0));
        },
        onPointerMove: R(
          e.onPointerMove,
          de((l) => {
            c.onItemEnter(l), !l.defaultPrevented && !e.disabled && !n.open && !i.current && (c.onPointerGraceIntentChange(null), i.current = window.setTimeout(() => {
              n.onOpenChange(!0), v();
            }, 100));
          })
        ),
        onPointerLeave: R(
          e.onPointerLeave,
          de((l) => {
            var h, p;
            v();
            const m = (h = n.content) == null ? void 0 : h.getBoundingClientRect();
            if (m) {
              const f = (p = n.content) == null ? void 0 : p.dataset.side, C = f === "right", E = C ? -5 : 5, x = m[C ? "left" : "right"], y = m[C ? "right" : "left"];
              c.onPointerGraceIntentChange({
                area: [
                  // Apply a bleed on clientX to ensure that our exit point is
                  // consistently within polygon bounds
                  { x: l.clientX + E, y: l.clientY },
                  { x, y: m.top },
                  { x: y, y: m.top },
                  { x: y, y: m.bottom },
                  { x, y: m.bottom }
                ],
                side: f
              }), window.clearTimeout(a.current), a.current = window.setTimeout(
                () => c.onPointerGraceIntentChange(null),
                300
              );
            } else {
              if (c.onTriggerLeave(l), l.defaultPrevented) return;
              c.onPointerGraceIntentChange(null);
            }
          })
        ),
        onKeyDown: R(e.onKeyDown, (l) => {
          var h;
          const m = c.searchRef.current !== "";
          e.disabled || m && l.key === " " || co[r.dir].includes(l.key) && (n.onOpenChange(!0), (h = n.content) == null || h.focus(), l.preventDefault());
        })
      }
    ) });
  }
);
cn.displayName = ie;
var an = "MenuSubContent", un = o.forwardRef(
  (e, t) => {
    const n = Gt(k, e.__scopeMenu), { forceMount: r = n.forceMount, ...s } = e, c = H(k, e.__scopeMenu), i = ve(k, e.__scopeMenu), a = on(an, e.__scopeMenu), d = o.useRef(null), u = T(t, d);
    return /* @__PURE__ */ g.jsx(le.Provider, { scope: e.__scopeMenu, children: /* @__PURE__ */ g.jsx(fe, { present: r || c.open, children: /* @__PURE__ */ g.jsx(le.Slot, { scope: e.__scopeMenu, children: /* @__PURE__ */ g.jsx(
      We,
      {
        id: a.contentId,
        "aria-labelledby": a.triggerId,
        ...s,
        ref: u,
        align: "start",
        side: i.dir === "rtl" ? "left" : "right",
        disableOutsidePointerEvents: !1,
        disableOutsideScroll: !1,
        trapFocus: !1,
        onOpenAutoFocus: (v) => {
          var l;
          i.isUsingKeyboardRef.current && ((l = d.current) == null || l.focus()), v.preventDefault();
        },
        onCloseAutoFocus: (v) => v.preventDefault(),
        onFocusOutside: R(e.onFocusOutside, (v) => {
          v.target !== a.trigger && c.onOpenChange(!1);
        }),
        onEscapeKeyDown: R(e.onEscapeKeyDown, (v) => {
          i.onClose(), v.preventDefault();
        }),
        onKeyDown: R(e.onKeyDown, (v) => {
          var h;
          const l = v.currentTarget.contains(v.target), m = ao[i.dir].includes(v.key);
          l && m && (c.onOpenChange(!1), (h = a.trigger) == null || h.focus(), v.preventDefault());
        })
      }
    ) }) }) });
  }
);
un.displayName = an;
function ln(e) {
  return e ? "open" : "closed";
}
function ye(e) {
  return e === "indeterminate";
}
function Ve(e) {
  return ye(e) ? "indeterminate" : e ? "checked" : "unchecked";
}
function Ro(e) {
  const t = document.activeElement;
  for (const n of e)
    if (n === t || (n.focus(), document.activeElement !== t)) return;
}
function Mo(e, t) {
  return e.map((n, r) => e[(t + r) % e.length]);
}
function _o(e, t, n) {
  const s = t.length > 1 && Array.from(t).every((u) => u === t[0]) ? t[0] : t, c = n ? e.indexOf(n) : -1;
  let i = Mo(e, Math.max(c, 0));
  s.length === 1 && (i = i.filter((u) => u !== n));
  const d = i.find(
    (u) => u.toLowerCase().startsWith(s.toLowerCase())
  );
  return d !== n ? d : void 0;
}
function Io(e, t) {
  const { x: n, y: r } = e;
  let s = !1;
  for (let c = 0, i = t.length - 1; c < t.length; i = c++) {
    const a = t[c].x, d = t[c].y, u = t[i].x, v = t[i].y;
    d > r != v > r && n < (u - a) * (r - d) / (v - d) + a && (s = !s);
  }
  return s;
}
function Ao(e, t) {
  if (!t) return !1;
  const n = { x: e.clientX, y: e.clientY };
  return Io(n, t);
}
function de(e) {
  return (t) => t.pointerType === "mouse" ? e(t) : void 0;
}
var $o = Wt, Uo = Ue, Bo = Ht, Ko = Yt, Wo = Ge, Go = Vt, Ho = Pe, Yo = zt, Vo = qt, Xo = Jt, zo = tn, Zo = nn, qo = rn, Qo = sn, Jo = cn, es = un;
export {
  Uo as A,
  Ko as C,
  Wo as G,
  Ho as I,
  Go as L,
  Bo as P,
  Xo as R,
  Jo as S,
  es as a,
  Yo as b,
  jo as c,
  zo as d,
  Zo as e,
  $o as f,
  Vo as g,
  Qo as h,
  qo as i
};
